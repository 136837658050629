import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import GlobalState from "../GlobalState";
import Api from "../components/Api";
import { Helmet } from "react-helmet";

export default function JoinTheTeam() {

    const { fixedTitles,global } = useContext(GlobalState);

    const [apiOptions, setApiOptions] = useState({});
    const [loading, setLoading] = useState(false)

    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [cvUpload, setCvUpload] = useState('');

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessages, setErrorMessages] = useState({});

    function joinTeam(e) {
        if (loading) return;

        setLoading(true);
        e.preventDefault();
        var formData = new FormData();
        formData.append('first_name', firstName);
        formData.append('middle_name', middleName);
        formData.append('last_name', lastName);
        formData.append('email', email);
        formData.append('phone_number', phoneNumber);
        formData.append('cv', cvUpload);
        setApiOptions({
            url: '/join-team',
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            then: (r) => {
                setFirstName('');
                setMiddleName('');
                setLastName('');
                setEmail('');
                setPhoneNumber('');
                setCvUpload('');
                setErrorMessages('');
                setSuccessMessage(true);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            },
            catch422: (errors) => {
                console.log(errors.data)
                setSuccessMessage('');
                setErrorMessages(errors);
            },
            finally: () => {
                setLoading(false);
            }
        });

    }

    useEffect(() => {
        window.triggerScroll();
    }, []);

    return (
        <Layout >
            <Helmet>
                <title>{global?.seo_pages['join-the-team'].title}</title>
                <meta name="title" content={global?.seo_pages['join-the-team'].title} />
                <meta name="description" content={global?.seo_pages['join-the-team'].description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={global?.seo_pages['join-the-team'].title} />
                <meta property="og:description" content={global?.seo_pages['join-the-team'].description} />
                <meta property="og:image" content={global?.seo_pages['join-the-team'].image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={global?.seo_pages['join-the-team'].title} />
                <meta property="twitter:description" content={global?.seo_pages['join-the-team'].description}/>
                <meta property="twitter:image" content={global?.seo_pages['join-the-team'].image} />
            </Helmet>
            {fixedTitles ?
                <div className="contact-background">
                    <div className="container py-lg-5">
                        <div className="text-center py-5 my-lg-5 mt-5" animate="up">
                            <h2 className="mb-0 pt-5">{fixedTitles.join_the_team}</h2>
                        </div>
                        <div className="row mx-lg-3 mx-md-2 pb-lg-0 pb-5">
                            <div className="col-lg-12" animate="left">
                                <div className="card-details shadow p-md-5 p-3 py-5">
                                    <p className="mb-5">{fixedTitles.join_the_team_text}</p>
                                    <h2 className="mb-4">{fixedTitles.apply_now}</h2>
                                    <Api options={apiOptions} />
                                    <form onSubmit={joinTeam}>
                                        <div className="row gx-5 contact-label pb-5 mb-3">

                                            <p className="mb-4">{fixedTitles.personal_details}</p>
                                            <div className="col-lg-4 mb-3">
                                                {
                                                    errorMessages.first_name ?
                                                        <input className="contact-form-page w-100 red-border" type="text" placeholder={fixedTitles.first_name_team} value={firstName} onChange={e => setFirstName(e.target.value)} />
                                                        :
                                                        <input className="contact-form-page w-100" type="text" value={firstName} placeholder={fixedTitles.first_name_team} onChange={e => setFirstName(e.target.value)} />
                                                }
                                                <div className="add-height">
                                                    {
                                                        errorMessages.first_name ?

                                                            <p className=" error-message mb-0">{errorMessages.first_name}</p>

                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                {
                                                    errorMessages.middle_name ?
                                                        <input className="contact-form-page w-100 red-border" placeholder={fixedTitles.middle_name_team} type="text" value={middleName} onChange={e => setMiddleName(e.target.value)} />
                                                        :
                                                        <input className="contact-form-page w-100" placeholder={fixedTitles.middle_name_team} type="text" value={middleName} onChange={e => setMiddleName(e.target.value)} />
                                                }
                                                <div className="add-height">
                                                    {
                                                        errorMessages.middle_name ?
                                                            <p className=" error-message mb-0">{errorMessages.middle_name}</p>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                {
                                                    errorMessages.last_name ?
                                                        <input className="contact-form-page w-100 red-border" placeholder={fixedTitles.last_name_team} type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                                                        :
                                                        <input className="contact-form-page w-100" placeholder={fixedTitles.last_name_team} type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                                                }
                                                <div className="add-height">
                                                    {
                                                        errorMessages.last_name ?
                                                            <p className=" error-message mb-0">{errorMessages.last_Name}</p>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-12 mb-3">

                                                {
                                                    errorMessages.email ?
                                                        <input placeholder={fixedTitles.email_address_team} className="contact-form-page w-100 red-border" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                                                        :
                                                        <input placeholder={fixedTitles.email_address_team} className="contact-form-page w-100" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                                                }
                                                <div className="add-height">
                                                    {
                                                        errorMessages.email ?
                                                            <p className=" error-message mb-0">{errorMessages.email}</p>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>

                                            <div className={"col-12" + (localStorage.getItem("language") === 'en' ? " " : " text-start")}>
                                                {
                                                    errorMessages.phone_number ?
                                                        <PhoneInput className="country-dropdown"
                                                            placeholder={fixedTitles.phone_number_team}
                                                            value={phoneNumber}
                                                            onChange={setPhoneNumber}
                                                            class="contact-form-page red-border w-100"

                                                        />
                                                        :
                                                        <PhoneInput className="border-color"
                                                            placeholder={fixedTitles.phone_number_team}
                                                            value={phoneNumber}
                                                            onChange={setPhoneNumber}
                                                            class="contact-form-page w-100"
                                                        />
                                                }
                                                <div className="add-height">
                                                    {
                                                        errorMessages.phone_number ?
                                                            <p className=" error-message mb-0">{errorMessages.phone_number}</p>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row ">
                                            <div className="contact-label">
                                                <p className="mb-4">{fixedTitles.resume}</p>
                                            </div>
                                            <p className="mb-4">{fixedTitles.resume_text}</p>

                                            <label className="input-file w-100 mb-3 d-flex ">


                                                {
                                                    errorMessages.cv ?
                                                        <>
                                                            <div className="contact-form-page red-border w-100"   >
                                                                <input name="name" type="file" className="file-input red-border" id="BtnBrowseHidden" onChange={e => setCvUpload(e.target.files[0])} />
                                                                <p className="mb-0 upload">{cvUpload?.name ? cvUpload?.name : fixedTitles.upload_cv}</p>
                                                            </div>
                                                            <label className="browse " for="BtnBrowseHidden" id="LblBrowse">
                                                                {fixedTitles.browse_button}
                                                            </label>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="contact-form-page w-100"   >
                                                                <input multiple="false" name="name" type="file" className="file-input" id="BtnBrowseHidden" onChange={e => setCvUpload(e.target.files[0])} />
                                                                <p className="mb-0 upload">{cvUpload?.name ? cvUpload?.name : fixedTitles.upload_cv}</p>
                                                            </div>
                                                            <label className="browse " for="BtnBrowseHidden" id="LblBrowse">
                                                                {fixedTitles.browse_button}
                                                            </label>
                                                        </>
                                                }
                                            </label>
                                            <div className="add-height">
                                                {
                                                    errorMessages.cv ?
                                                        <p className=" error-message mb-0">{errorMessages.cv}</p>
                                                        :
                                                        null
                                                }
                                            </div>


                                        </div>
                                        <button disabled={loading} type="submit" className="button d-flex align-items-center pt-4">
                                            {
                                                loading ?
                                                    <h3 className="mb-0 me-3">{fixedTitles.loading}</h3>
                                                    :
                                                    <h3 className="mb-0 me-3">{fixedTitles?.contact_button}</h3>
                                            }
                                            {
                                                localStorage.getItem("language") === 'en' ?
                                                    <img src="/assets/images/button-arrow.svg" alt="button" />
                                                    :
                                                    <img src="/assets/images/button-ar.svg" alt="button" />
                                            }
                                        </button>
                                        {
                                            successMessage ?
                                                <div className="add-height">
                                                    <p className="success">{fixedTitles.success_message}</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
        </Layout>
    )
}