export default function Values(props) {
    return (
        <div className="values">
            <div className="position-relative">
                <div className="ratio value-ratio">
                    <img src={props.image} alt="values" />
                </div>

                <div className="values-content-remove">
                    <h4 className="">{props.title}</h4>
                    <p>{props.subtitle}</p>
                </div>

                <div className="values-content">
                    <h4 className="mb-3">{props.title}</h4>
                </div>
                <div className="opacity"></div>

            </div>
        </div>
    )
}