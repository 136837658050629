import { useContext, useEffect, useState } from "react";
import Api from "../components/Api";
import Layout from "../components/Layout";
import GlobalState from "../GlobalState";
import { Helmet } from "react-helmet";

export default function Contact() {
    const { fixedTitles, global } = useContext(GlobalState);

    const [apiOptions, setApiOptions] = useState({});

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [subscribe, setSubcribe] = useState('');
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessages, setErrorMessages] = useState({});

    function contactUs(e) {
        e.preventDefault();

        if (loading) return;

        setLoading(true);
        setApiOptions({
            url: '/contact-us',
            method: 'post',
            data: {
                'first_name': firstName,
                'last_name': lastName,
                'email': email,
                'phone_number': phoneNumber,
                'message': message,
                'subscribe': subscribe,
            },
            then: (r) => {
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber('');
                setMessage('');
                setSubcribe('')
                setErrorMessages('');
                setSuccessMessage(true);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            },
            catch422: (errors) => {
                setSuccessMessage('');
                setErrorMessages(errors);
            },
            finally: () => {
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        window.triggerScroll();
    }, []);

    return (
        <Layout activePage="contact">
            <Helmet>
                <title>{global?.seo_pages['contact'].title}</title>
                <meta name="title" content={global?.seo_pages['contact'].title} />
                <meta name="description" content={global?.seo_pages['contact'].description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={global?.seo_pages['contact'].title} />
                <meta property="og:description" content={global?.seo_pages['contact'].description} />
                <meta property="og:image" content={global?.seo_pages['contact'].image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={global?.seo_pages['contact'].title} />
                <meta property="twitter:description" content={global?.seo_pages['contact'].description}/>
                <meta property="twitter:image" content={global?.seo_pages['contact'].image} />
            </Helmet>
            {
                global ?
                    <div className="contact-background">
                        {fixedTitles ?
                            <div className="container-fluid py-lg-5">
                                <div className="text-center py-5 my-lg-5 mt-5" animate="up">
                                    <h2 className="mb-0 pt-5">{fixedTitles.contact}</h2>
                                </div>

                                <div className="row pb-lg-0 mx-lg-3 mx-md-2 pb-5 gx-lg-4">
                                    <div className="col-lg-4 col-md-12 pb-5 " animate="left">
                                        <div className="card-details shadow  p-md-5 p-3 py-5 ">
                                            <div className="">
                                                <h2 className="mb-4">{fixedTitles.our_office}</h2>
                                                <div className="mb-3 pb-2" dangerouslySetInnerHTML={{ __html: global.contact_details.text }} />

                                                <a target="_blank" rel="noreferrer" className="details" href={global.contact_details.location_url}>
                                                    <div className="d-flex align-items-center">
                                                        <img src={global.contact_details.location_icon} alt="icon" />
                                                        <p className="ms-2 mb-0">{global.contact_details.location_label}</p>
                                                    </div>
                                                </a>
                                                {
                                                    global.contact_details.phone_number ?
                                                        <a target="_blank" rel="noreferrer" className="details" href={"tel:" + global.contact_details.phone_number}>
                                                            <div className="d-flex align-items-center">
                                                                <img src={global.contact_details.phone_icon} alt="icon" />
                                                                <p className="ms-2 mb-0">{global.contact_details.phone_number}</p>
                                                            </div>
                                                        </a>
                                                        :
                                                        null
                                                }

                                                <a target="_blank" rel="noreferrer" className="details" href={"mailTo:" + global.contact_details.email}>
                                                    <div className="d-flex align-items-center">
                                                        <img src={global.contact_details.email_icon} alt="icon" />
                                                        <p className="ms-2 mb-0">{global.contact_details.email}</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-12" animate="right">
                                        <div className="card-details shadow p-md-5 p-3 py-5 ">
                                            <div className=" ">
                                                <p>{fixedTitles.contact_text_1}</p>
                                                <p className="mb-0">{fixedTitles.contact_text_2}</p>
                                                <Api options={apiOptions} />
                                                <form className=" pt-lg-5 pt-md-5 pt-3" onSubmit={contactUs}>
                                                    <div className="contact-label">
                                                        <div >
                                                            <p className="mb-lg-5 mb-md-4 mb-3">{fixedTitles.first_name}</p>
                                                            {
                                                                errorMessages.first_name ?
                                                                    <input className="contact-form-page w-100 red-border" type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                                                                    :
                                                                    <input className="contact-form-page w-100" type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                                                            }
                                                            <div className="add-height">
                                                                {
                                                                    errorMessages.first_name ?

                                                                        <p className=" error-message mb-0">{errorMessages.first_name}</p>

                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <p className="mb-lg-5 mb-md-5 mb-3">{fixedTitles.last_name_label}</p>
                                                            {
                                                                errorMessages.last_name ?
                                                                    <input className="contact-form-page w-100 red-border" type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                                                                    :
                                                                    <input className="contact-form-page w-100" type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                                                            }
                                                            <div className="add-height">
                                                                {
                                                                    errorMessages.last_name ?
                                                                        <p className=" error-message mb-0">{errorMessages.last_name}</p>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <p className="mb-lg-5 mb-md-5 mb-3">{fixedTitles.email_label}</p>
                                                            {
                                                                errorMessages.email ?
                                                                    <input className="contact-form-page w-100 red-border" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                                                                    :
                                                                    <input className="contact-form-page w-100" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                                                            }
                                                            <div className="add-height">
                                                                {
                                                                    errorMessages.email ?
                                                                        <p className=" error-message mb-0">{errorMessages.email}</p>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <p className="mb-lg-5 mb-md-5 mb-3">{fixedTitles.phone_number_label}</p>
                                                            {
                                                                errorMessages.phone_number ?
                                                                    <input className="contact-form-page w-100 red-border" type="number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                                                                    :
                                                                    <input className="contact-form-page w-100" type="number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                                                            }
                                                            <div className="add-height">
                                                                {
                                                                    errorMessages.phone_number ?
                                                                        <p className=" error-message mb-0">{errorMessages.phone_number}</p>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <p className="mb-lg-5 mb-md-5 mb-3">{fixedTitles.your_message_label}</p>
                                                            {errorMessages.message ?
                                                                <textarea rows={3} className="contact-form-page w-100 red-border" type="text" value={message} onChange={e => setMessage(e.target.value)} />
                                                                :
                                                                <textarea rows={3} className="contact-form-page w-100 " type="text" value={message} onChange={e => setMessage(e.target.value)} />
                                                            }
                                                            {
                                                                errorMessages.message ?
                                                                    <p className=" error-message mb-0">{errorMessages.message}</p>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                    </div>
                                                    <p className="">{fixedTitles.agreement_text}</p>
                                                    <div className="d-flex align-items-center">
                                                        <label className="radio-button me-4">
                                                            <input type="radio" name="radio" onChange={() => setSubcribe(1)} />
                                                            <span className="checkmark"></span>
                                                            <p>{fixedTitles.yes}</p>
                                                        </label>

                                                        <label className="radio-button">
                                                            <input type="radio" name="radio" onChange={() => setSubcribe(0)} />
                                                            <span className="checkmark"></span>
                                                            <p>{fixedTitles.no}</p>
                                                        </label>
                                                    </div>
                                                    {/* <Link to="/contact"> */}
                                                    <button disabled={loading} className="button d-flex align-items-center pt-4" type="submit">
                                                        {
                                                            loading ?
                                                                <h3 className="mb-0 me-3">{fixedTitles.loading}</h3>
                                                                :
                                                                <h3 className="mb-0 me-3">{fixedTitles?.contact_button}</h3>
                                                        }
                                                        {
                                                            localStorage.getItem("language") === 'en' ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24.883" height="20.228" viewBox="0 0 24.883 20.228">
                                                                    <g id="Group_103" data-name="Group 103" transform="translate(-6091.364 -4956.472)">
                                                                        <line id="Line_25" data-name="Line 25" x2="22.48" transform="translate(6092.364 4966.576)" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                        <line id="Line_26" data-name="Line 26" x1="6.19" y1="8.72" transform="translate(6108.663 4957.866)" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                        <line id="Line_27" data-name="Line 27" y1="8.72" x2="6.19" transform="translate(6108.663 4966.585)" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                    </g>
                                                                </svg>

                                                                :
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24.883" height="20.228" viewBox="0 0 24.883 20.228">
                                                                    <g id="Group_277" data-name="Group 277" transform="translate(1.394 1.394)">
                                                                        <g id="Group_276" data-name="Group 276">
                                                                            <line id="Line_80" data-name="Line 80" x1="22.48" transform="translate(0.009 8.729)" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                            <line id="Line_81" data-name="Line 81" x2="6.19" y2="8.72" transform="translate(0 8.719)" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                            <line id="Line_82" data-name="Line 82" x1="6.19" y2="8.72" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                        }
                                                    </button>

                                                    {
                                                        successMessage ?
                                                            <div className="add-height">
                                                                <p className="success">{fixedTitles.success_message}</p>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {/* </Link> */}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
            }
        </Layout>
    )
}