export default function HomeComponent(props) {
    return (
        <div className="position-relative">
            <div className="ratio services-ratio">
                <img src={props.image} alt="service" />
            </div>
            <div className="opacity"></div>
            <div className="service-title">
                <h4>{props.title}</h4>
            </div>
            <div className="position-relative">

                <div className="row service-section mx-1">
                    <div className="col-auto ps-1">
                        {
                            localStorage.getItem("language") === 'en' ?
                                <img className="arrow-image" src="/assets/images/image-arrow.svg" alt="" />
                                :
                                <img className="arrow-image" src="/assets/images/ar-arrow.svg" alt="" />
                        }
                    </div>
                    <div className="col text-end">
                        <img className="add" src="/assets/images/add.svg" alt="add" />
                    </div>
                </div>
            </div>
        </div>
    )
}