import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

export default function Api(props) {

    const navigate = useNavigate();
    const [validationMessages, setValidationMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    const checkValidationErros = (errors) => {
        let newValidationMessages = [];
        for (const key in errors) {
            for (let i = 0; i < errors[key].length; i++) {
                newValidationMessages.push(errors[key][i]);
            }
        }
        setValidationMessages(newValidationMessages);
    }

    useEffect(() => {
        if (props.options.url) {
            // if (loading) return;

            setLoading(true);
            setValidationMessages([]);

            axios({
                url: props.options.url,
                method: props.options.method,
                params: props.options.params,
                data: props.options.data,
                headers: props.options.headers ? props.options.header : {
                    Accept: 'application/json'
                },
            })
                .then(props.options.then)
                .catch(r => {
                    if (r.response?.status === 422) {
                        if (props.options.catch422) props.options.catch422(r.response.data.errors);
                        else checkValidationErros(r.response.data.errors);
                    } else if (r.response?.status === 404) {
                        navigate('/404');
                    } else if (r.response?.status === 401) {
                        localStorage.removeItem('auth_token');
                        navigate('/login');
                    } else if (r.response?.status === 302) {
                        if (props.options.catch302) props.options.catch302(r.response.data.errors);
                    } else {
                        alert(r.message)
                    }
                })
                .finally(() => {
                    setLoading(false);
                    if (props.options.finally) props.options.finally();
                });
        }
    }, [props.options, navigate]);

    useEffect(() => {
        window.triggerScroll();
    }, []);

    return (
        <>
            {
                validationMessages.length > 0 && props.options.url !== 'save-form-1' && (
                    <div id="errors-popup" className="error-popup-api  fade-in">
                        <div className="pop-up-api-box">
                            <div className="content background-light py-3">
                                {
                                    validationMessages.map((message, i) => (
                                        <p className="mb-2 error-message" key={i}>{message}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            {
                loading && (
                    <div className="background-loader">
                        <div className="loadingio-spinner-dual-ball-0wy5g10wnilq "> 
                            <div className="ldio-73os6v6cud3">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}