import { useContext, useEffect, useState } from "react";
import Api from "../components/Api";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import GlobalState from "../GlobalState";
import { Helmet } from "react-helmet";

export default function Insights() {
    const [apiOptions, setApiOptions] = useState({});
    const [insights, setInsights] = useState('');


    const { fixedTitles, global } = useContext(GlobalState);

    useEffect(() => {
        setApiOptions({
            url: '/insights-items',
            method: 'get',
            then: (r) => {
                setInsights(r.data.page_items.insights)
                window.triggerScroll();
            }
        });
    }, []);


    return (
        <Layout activePage="insights">
            <Helmet>
                <title>{global?.seo_pages['insights'].title}</title>
                <meta name="title" content={global?.seo_pages['insights'].title} />
                <meta name="description" content={global?.seo_pages['insights'].description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={global?.seo_pages['insights'].title} />
                <meta property="og:description" content={global?.seo_pages['insights'].description} />
                <meta property="og:image" content={global?.seo_pages['insights'].image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={global?.seo_pages['insights'].title} />
                <meta property="twitter:description" content={global?.seo_pages['insights'].description} />
                <meta property="twitter:image" content={global?.seo_pages['insights'].image} />
            </Helmet>
            <Api options={apiOptions} />
            {
                insights ?
                    <div className="insights">
                        {fixedTitles ?
                            <>
                                <div className="container-fluid py-5">
                                    <div className="text-center py-lg-5 my-5" >
                                        <h2 className="mb-0 pt-5">{fixedTitles.insights}</h2>
                                    </div>
                                    {
                                        insights.map((insight, index) =>
                                            insight?.pdf_file ?
                                                <a href={insight?.pdf_file} target="_blank" key={index} download>
                                                    <div className={"row align-items-center services mx-lg-3 mx-md-2 pb-lg-5 mb-5 " + (index % 2 === 0 ? "" : " flex-row-reverse d-flex")} key={index}>
                                                        <div className="col-12">
                                                            <div className="row d-sm-flex d-block ">
                                                                <div className="col">
                                                                    <h5 className="flex-grow-1 date">{insight.date}</h5>
                                                                </div>

                                                                <div className="col-auto">
                                                                    <h5>{insight.article}</h5>
                                                                </div>
                                                            </div>
                                                            <hr className="mb-5 mt-0" />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 ">
                                                            <div className={index % 2 === 0 ? "me-lg-5 pe-lg-4 pb-lg-0 pb-4" : "ms-lg-5 ps-lg-4 pb-lg-0 pb-4"}>
                                                                <h2 className="service-title-section mb-lg-4 mb-2 ">{insight.title}</h2>
                                                                <img className="quotes" src="/assets/images/quotes.svg" alt="quotes" />
                                                                <p className="py-4 mb-0 justify-text">{insight.insight_text}</p>
                                                                <div className="text-end mb-lg-4 mb-2">
                                                                    <img className="quotes" src="/assets/images/quotes1.svg" alt="quotes" />
                                                                </div>
                                                                {
                                                                    insight?.pdf_file ?
                                                                        <a className="read-more" href={insight?.pdf_file} target="_blank" key={index} download>
                                                                            {fixedTitles.read_more}
                                                                        </a>
                                                                        :

                                                                        <Link className="read-more" to={"/insights/" + insight.slug}>{fixedTitles.read_more}</Link>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 ">
                                                            <div className="ratio insights-ratio shadow">
                                                                <img src={insight.image_outside} alt="banner" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                :

                                                <Link to={"/insights/" + insight.slug} key={index}>
                                                    <div className={"row align-items-center services mx-lg-3 mx-md-2 pb-lg-5 mb-5 " + (index % 2 === 0 ? "" : " flex-row-reverse d-flex")} key={index}>
                                                        <div className="col-12">
                                                            <div className="row d-sm-flex d-block ">
                                                                <div className="col">
                                                                    <h5 className="flex-grow-1 date">{insight.date}</h5>
                                                                </div>

                                                                <div className="col-auto">
                                                                    <h5>{insight.article}</h5>
                                                                </div>
                                                            </div>
                                                            <hr className="mb-5 mt-0" />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 ">
                                                            <div className={index % 2 === 0 ? "me-lg-5 pe-lg-4 pb-lg-0 pb-4" : "ms-lg-5 ps-lg-4 pb-lg-0 pb-4"}>
                                                                <h2 className="service-title-section mb-lg-4 mb-2 ">{insight.title}</h2>
                                                                <img className="quotes" src="/assets/images/quotes.svg" alt="quotes" />
                                                                <p className="py-4 mb-0 justify-text">{insight.insight_text}</p>
                                                                <div className="text-end mb-lg-4 mb-2">
                                                                    <img className="quotes" src="/assets/images/quotes1.svg" alt="quotes" />
                                                                </div>
                                                                <Link className="read-more" to={"/insights/" + insight.slug}>{fixedTitles.read_more}</Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 ">
                                                            <div className="ratio insights-ratio shadow">
                                                                <img src={insight.image_outside} alt="banner" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                        )
                                    }
                                </div>

                                <div className="position-relative">
                                    {localStorage.getItem("language") === 'en' ?
                                        <div className="insights-section-bg">
                                            <img src="/assets/images/insights-bg.png" alt="insights" />
                                        </div>
                                        :
                                        <div className="insights-section-bg">
                                            <img src="/assets/images/insights-bg-ar.png" alt="insights" />
                                        </div>
                                    }
                                    <div className="container-fluid py-5">
                                        <form className="row  mx-lg-3 mx-md-2" action="https://strategyhub.us14.list-manage.com/subscribe/post?u=41f560295232b2c03b2bc6029&amp;id=dcfbc677d1" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" novalidate>
                                            <div className="col-lg-10">
                                                <h2 className="mb-0 pb-5">{fixedTitles.subscribe}</h2>
                                                <p className="mb-5">{fixedTitles.subscribe_text}</p>

                                                {/* <Link to="/insights"> */}
                                                <button className="button d-flex align-items-center " type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">
                                                    <h3 className="mb-0 me-3">{fixedTitles.subscribe_button}</h3>
                                                    {localStorage.getItem("language") === 'en' ?
                                                        <img src="/assets/images/button-arrow.svg" alt="button" />
                                                        :
                                                        <img src="/assets/images/button-ar.svg" alt="button" />
                                                    }
                                                </button>
                                                {/* </Link> */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }
                    </div>
                    :
                    null
            }
        </Layout>
    )
}