import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../components/Api";
import Layout from "../components/Layout";
import GlobalState from "../GlobalState";
import { Helmet } from "react-helmet";

export default function Services() {

    const [apiOptions, setApiOptions] = useState({});
    const [services, setServices] = useState('');

    const { fixedTitles, global } = useContext(GlobalState);

    useEffect(() => {
        setApiOptions({
            url: '/services',
            method: 'post',
            then: (r) => {
                setServices(r.data)
            }
        });
    }, []);

    return (
        <Layout activePage="services">
            <Helmet>
                <title>{global?.seo_pages['services'].title}</title>
                <meta name="title" content={global?.seo_pages['services'].title} />
                <meta name="description" content={global?.seo_pages['services'].description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={global?.seo_pages['services'].title} />
                <meta property="og:description" content={global?.seo_pages['services'].description} />
                <meta property="og:image" content={global?.seo_pages['services'].image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={global?.seo_pages['services'].title} />
                <meta property="twitter:description" content={global?.seo_pages['services'].description} />
                <meta property="twitter:image" content={global?.seo_pages['services'].image} />
            </Helmet>
            <Api options={apiOptions} />
            {
                services ?
                    <div className="container-fluid pt-5">
                        {
                            fixedTitles ?
                                <>

                                    <div className="text-center py-lg-5 my-5" >
                                        <h2 className="mb-0 pt-5">{fixedTitles.services}</h2>
                                    </div>

                                    {
                                        services.map((service, index) =>
                                            <Link to={"/services/" + service.slug} key={index}>
                                                <div className={"row align-items-center services mx-lg-3 mx-md-2 pb-lg-5 mb-5 " + (index % 2 === 0 ? "" : " flex-row-reverse d-flex")}>
                                                    <hr className="mb-5" />
                                                    <div className="col-lg-6 col-md-6 ">
                                                        <div className={(index % 2 === 0 ? "me-lg-5 pe-lg-4 pb-lg-0 pb-4" : "ms-lg-5 ps-lg-4 pb-lg-0 pb-4")}>
                                                            <h2 className="service-title-section  mb-4">{service?.banner_title}</h2>
                                                            <p className="justify-text">{service?.service_text}</p>
                                                            <Link className="read-more" to={"/services/" + service.slug}>{fixedTitles.read_more}</Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 ">
                                                        <div className="ratio service-ratio shadow">
                                                            <img src={service.banner_image} alt="service" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }
                                </>
                                :
                                null
                        }
                    </div>
                    :
                    null
            }
        </Layout>
    )
}