import { useContext, useEffect, useState } from "react";
import Api from "../components/Api";
import Banner from "../components/Banner";
import Layout from "../components/Layout";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import HomeComponent from "../components/HomeComponent";
import { Link, useParams } from "react-router-dom";
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import "swiper/css/pagination";
import { Pagination } from "swiper";
import GlobalState from "../GlobalState";
import { Helmet } from "react-helmet";

export default function SingleInsights() {
    const [apiOptions, setApiOptions] = useState({});
    const [insights, setInsights] = useState();
    const [moreInsights, setMoreInsights] = useState();

    const { fixedTitles } = useContext(GlobalState);

    const params = useParams();

    // $query->where('slug', '!=', $slug)->with('relat')

    useEffect(() => {
        setInsights();
        setMoreInsights();
        setApiOptions({
            url: '/insights-items/' + params.slug,
            method: 'get',
            then: (r) => {
                setInsights(r?.data?.page_items?.insight);
                setMoreInsights(r.data.page_items?.more_insights);
                window.triggerScroll();
            }
        });
    }, [params.slug]);

    console.log(insights);
    return (

        <Layout activePage="insights" hasEnglish={insights?.show_only_english} hasArabic={insights?.show_only_arabic}>
            {fixedTitles ?
                <div>

                    <Api options={apiOptions} />

                    {insights ?
                        <>
                            <Helmet>
                                <title>{insights.seo_title}</title>
                                <meta name="title" content={insights.seo_title} />
                                <meta name="description" content={insights.seo_description} />

                                <meta property="og:type" content="website" />
                                <meta property="og:url" content={window.location} />
                                <meta property="og:title" content={insights.seo_title} />
                                <meta property="og:description" content={insights.seo_description} />
                                <meta property="og:image" content={insights.seo_image} />

                                <meta property="twitter:card" content="summary_large_image" />
                                <meta property="twitter:url" content={window.location} />
                                <meta property="twitter:title" content={insights.seo_title} />
                                <meta property="twitter:description" content={insights.seo_description} />
                                <meta property="twitter:image" content={insights.seo_image} />
                            </Helmet>
                            <Banner
                                scroll="insights-section-1"
                                image={insights.banner_image}
                                title={insights.title}
                            />
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="single-service" id="insights-section-1">
                                        <div className="container py-5">
                                            <div className="row justify-content-between" animate="left">
                                                <div className="row d-sm-flex d-block">
                                                    <div className="col">
                                                        <h3 className="flex-grow-1 date">{insights.date}</h3>
                                                    </div>
                                                    <div className="col-auto">
                                                        <h3 className="mb-0 justify-text">{insights.title}</h3>
                                                    </div>
                                                </div>
                                                <hr className="mb-5 mt-0" />
                                                <h3 className="mb-0 justify-text">{insights?.inside_text_1}</h3>
                                                <div className="col-lg-6 pt-5" animate="left">
                                                    <h3 className="mb-0 justify-text">{insights?.inside_text_2}</h3>
                                                </div>
                                                <div className="col-lg-6 pt-5" animate="right">
                                                    <div className="ratio single-service-1">
                                                        <img src={insights.inside_image} alt="service" />
                                                    </div>
                                                </div>
                                                <div className="col-12 pt-5">
                                                    <div className="mb-0 justify-text" dangerouslySetInnerHTML={{ __html: insights?.inside_text_3 }} />
                                                </div>

                                            </div>
                                            {
                                                insights.image_1 || insights.image_2 || insights?.inside_text_4 ?
                                                    <>
                                                        <div className="row pt-3">
                                                            {insights.image_1 ?
                                                                <div className="col-lg-6 col-md-6 col-12 pb-3">
                                                                    <div className="ratio add-image">
                                                                        <img src={insights.image_1} alt="insights" />
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            {insights.image_2 ?
                                                                <div className="col-lg-6 col-md-6 col-12 pb-3">
                                                                    <div className="ratio add-image">
                                                                        <img src={insights.image_2} alt="insights" />
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        {insights?.inside_text_4 ?
                                                            <div className="col-12 pt-3">
                                                                <div className="mb-0 justify-text" dangerouslySetInnerHTML={{ __html: insights?.inside_text_4 }} />
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </>
                                                    : null
                                            }
                                        </div>

                                        <div className="container" animate="right">
                                            <div className="">
                                                <Swiper
                                                    spaceBetween={15}
                                                    loop={true}
                                                    slidesPerView={1}
                                                    onSlideChange={() => console.log('slide change')}
                                                    onSwiper={(swiper) => console.log(swiper)}
                                                    pagination={{
                                                        dynamicBullets: true,
                                                    }}
                                                    modules={[Pagination]}
                                                    breakpoints={{
                                                        1199.98: {
                                                            slidesPerView: 3,
                                                        },

                                                        991.98: {
                                                            slidesPerView: 3,
                                                        },

                                                        // when window width is >= 767.98px
                                                        767.98: {
                                                            slidesPerView: 2,
                                                        },
                                                        575.98: {
                                                            slidesPerView: 1,
                                                        },
                                                    }}
                                                >

                                                    {
                                                        insights.images.map((image, index) =>

                                                            <SwiperSlide key={index}>
                                                                <div className=" pb-5">
                                                                    <div className="ratio images-insights ">

                                                                        <img src={image} alt="inside-images" />
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                    }
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative index">
                                <div className="ratio section-between">
                                    <img src="/assets/images/single-service-bg.jpg" alt="bg" />
                                </div>
                            </div>
                        </>
                        :
                        null
                    }
                    {moreInsights ?
                        <div className="position-relative">
                            <div className="container-fluid   pb-5 mb-lg-5">
                                <div className="mx-lg-3 mx-md-2">
                                    <div className="text-center py-4">
                                        <h2>{fixedTitles.more_insights}</h2>
                                    </div>
                                    <div className="justify-content-end d-flex pb-4">
                                        <div className="swiper-button-prev  me-5">
                                            <img src="/assets/images/prev.svg" alt="prev" />
                                        </div>
                                        <div className="swiper-button-next ">
                                            <img src="/assets/images/next.svg" alt="next" />
                                        </div>
                                    </div>
                                    <Swiper
                                        spaceBetween={15}
                                        loop={true}
                                        slidesPerView={1}
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        modules={[Navigation]}

                                        navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev'
                                        }}
                                        breakpoints={{
                                            1199.98: {
                                                slidesPerView: 4,
                                            },

                                            991.98: {
                                                slidesPerView: 3,
                                            },

                                            // when window width is >= 767.98px
                                            767.98: {
                                                slidesPerView: 3,
                                            },
                                            575.98: {
                                                slidesPerView: 2,
                                            },
                                        }}
                                    >
                                        {
                                            moreInsights.map((moreInsight, index) =>
                                                <SwiperSlide key={index}>
                                                    <Link to={"/insights/" + moreInsight.slug}>
                                                        <div className="services">
                                                            <HomeComponent
                                                                title={moreInsight.title}
                                                                image={moreInsight.image_outside}
                                                            />
                                                        </div>
                                                    </Link>
                                                </SwiperSlide>
                                            )
                                        }
                                    </Swiper>

                                </div>
                            </div>
                            <div className="bg-single-services">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100.37" height="103.729" viewBox="0 0 192.37 103.729">
                                    <path id="Path_3" data-name="Path 3" d="M1727.629,3351.714l3.848,7.71,188.522-94.1v-9.632Z" transform="translate(-1727.629 -3255.695)" fill="#80808061" />
                                </svg>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                :
                null
            }
        </Layout>
    )
}