import { useEffect, useRef } from "react";

export default function Banner(props) {
    const videoRef = useRef();

    // function toggleMute() {
    //     videoRef.current.muted = !videoRef.current.muted;
    // }

    useEffect(() => {
        window.triggerScroll();
    }, []);

    function scrollTop() {
        const element = document.getElementById(props.scroll)
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return (    
        <div className="position-relative">
            <div className="container-fluid">
                <div className="row text-header ms-lg-3 ms-md-2" animate="left">
                    <div className="col-lg-9 col-md-7 col-sm-9 col-9">
                        <h5 className="mb-0">{props.title}</h5>
                    </div>
                </div>
            </div>
            {props.video ?
                <div className="ratio background-image">
                    <video ref={videoRef} muted loop autoPlay>
                        <source src={props.video} type='video/mp4' />
                    </video>
                </div>
                :
                <div className="ratio background-image">
                    <img src={props.image} alt="" />
                </div>
            }
            <div className="opacity-header"></div>
            <div className="section-1">
                <div className="container-fluid" >
                    <div className="text-end mx-lg-3">

                        <img onClick={scrollTop} className="arrow-down" src="/assets/images/header-arrow.svg" alt="video" />

                    </div>
                </div>
            </div>
            {/* <p className="position-relative" onClick={toggleMute}>Toggle Mute</p> */}
        </div>
    )
}