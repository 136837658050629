import { useContext, useEffect, useState } from "react";
import Banner from "../components/Banner";
import Layout from "../components/Layout";
import ServiceCards from "../components/ServiceCards";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import HomeComponent from "../components/HomeComponent";
import { Link, useParams } from "react-router-dom";
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import SingleServicePage from "../components/SingleServicePage";
import Api from "../components/Api";
import GlobalState from "../GlobalState";
import { Helmet } from "react-helmet";

export default function InternationalAndPublicPolicy() {

    const [service, setService] = useState();
    const [apiOptions, setApiOptions] = useState({});

    const [apiMoreOptions, setApiMoreOptions] = useState({});
    const [moreServices, setMoreServices] = useState();

    const { fixedTitles } = useContext(GlobalState);

    const params = useParams();

    useEffect(() => {
        setService();
        setMoreServices();

        setApiOptions({
            url: '/services',
            method: 'post',
            data: {
                "custom_validation": [
                    {
                        "constraint": "where",
                        "value": [
                            "slug",
                            params.slug, // Slug from the url
                        ]
                    },
                    {
                        "constraint": "with",
                        "value": [
                            "cards"
                        ]
                    }
                ]
            },
            then: (r) => {
                setService(r.data[0])
                window.triggerScroll();
            }
        });

        setApiMoreOptions({
            url: '/services',
            method: 'post',
            data: {
                "custom_validation": [
                    {
                        "constraint": "where",
                        "value": [
                            "slug",
                            "!=",
                            params.slug, // Replace with slug from url
                        ]
                    }
                ]
            },
            then: (r) => {
                setMoreServices(r.data)
                window.triggerScroll();
            }
        });
    }, [params.slug]);


    return (
        <Layout activePage="services">

            <Api options={apiOptions} />
            <Api options={apiMoreOptions} />

            {
                service ?
                    <>
            <Helmet>
                <title>{service.seo_title}</title>
                <meta name="title" content={service.seo_title} />
                <meta name="description" content={service.seo_description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={service.seo_title} />
                <meta property="og:description" content={service.seo_description} />
                <meta property="og:image" content={service.seo_image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={service.seo_title} />
                <meta property="twitter:description" content={service.seo_description}/>
                <meta property="twitter:image" content={service.seo_image} />
            </Helmet>
                        <Banner
                            scroll="service-section-1"
                            image={service.banner_image}
                            title={service.banner_title}
                        />

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className=" single-service" id="service-section-1">
                                    <SingleServicePage
                                        title={service.banner_title}
                                        subtitle={service.inside_text_1}
                                        paragraphOne={service.inside_text_2}
                                        // paragraphTwo={service?.inside_text_3}
                                        image={service.inside_image}
                                    />

                                    <div className="container" animate="up">
                                        <div className="row ">
                                            <h3 className="mb-0 justify-text">{service?.inside_text_3}</h3>
                                            <h3 className="col-12 mb-4 justify-text">{service?.cardSectionTitle}</h3>
                                            <div className="justify-content-end d-flex pb-4">
                                                <div className="swiper-button-prev  me-5">
                                                    <img src="/assets/images/prev.svg" alt="prev" />
                                                </div>
                                                <div className="swiper-button-next ">
                                                    <img src="/assets/images/next.svg" alt="next" />
                                                </div>
                                            </div>
                                            <Swiper className="cards-swiper pb-5 mx-3 mx-sm-0"
                                                spaceBetween={15}
                                                loop={true}
                                                slidesPerView={1.3}
                                                modules={[Navigation]}
                                                navigation={{
                                                    nextEl: '.swiper-button-next',
                                                    prevEl: '.swiper-button-prev'
                                                }}
                                                breakpoints={{
                                                    1199.98: {
                                                        slidesPerView: 4,
                                                    },

                                                    991.98: {
                                                        slidesPerView: 3,
                                                    },

                                                    // when window width is >= 767.98px
                                                    767.98: {
                                                        slidesPerView: 2,
                                                    },
                                                    575.98: {
                                                        slidesPerView: 2,
                                                    },
                                                }}
                                            >
                                                {console.log(service)}
                                                {
                                                    service?.cards?.map((card, index) => (

                                                        <SwiperSlide key={index}>
                                                            <ServiceCards className="mx-1"
                                                                title={card.title}
                                                                image={card.full_path_image}
                                                            />
                                                        </SwiperSlide>
                                                    ))
                                                }
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="position-relative index">
                                <div className="ratio section-between">
                                    <img src="/assets/images/single-service-bg.jpg" alt="bg" />
                                </div>
                            </div>
                            {moreServices ?
                                <div className="position-relative">
                                    <div className="container-fluid  pb-5 mb-lg-5">
                                        <div className="mx-lg-3 mx-md-2">
                                            <div className="text-center py-4">
                                                <h2>{fixedTitles?.more_services}</h2>
                                            </div>
                                            <div className="justify-content-end d-flex pb-4">
                                                <div className="swiper-button-prev  me-5">
                                                    <img src="/assets/images/prev.svg" alt="prev" />
                                                </div>
                                                <div className="swiper-button-next ">
                                                    <img src="/assets/images/next.svg" alt="next" />
                                                </div>
                                            </div>
                                            <Swiper
                                                className="more-services-swiper"
                                                spaceBetween={15}
                                                loop={true}
                                                slidesPerView={1}
                                                modules={[Navigation]}
                                                navigation={{
                                                    nextEl: '.swiper-button-next',
                                                    prevEl: '.swiper-button-prev'
                                                }}
                                                breakpoints={{
                                                    991.98: {
                                                        slidesPerView: 3,
                                                    },

                                                    // when window width is >= 767.98px
                                                    767.98: {
                                                        slidesPerView: 2,
                                                    },
                                                    575.98: {
                                                        slidesPerView: 2,
                                                    },
                                                }}
                                            >
                                                {
                                                    moreServices.map((moreService, index) =>
                                                        <SwiperSlide ket={index}>
                                                            <Link to={"/services/" + moreService.slug}>
                                                                <div className="services">
                                                                    <HomeComponent
                                                                        title={moreService.banner_title}
                                                                        image={moreService.banner_image}
                                                                    />
                                                                </div>
                                                            </Link>
                                                        </SwiperSlide>
                                                    )
                                                }
                                            </Swiper>

                                        </div>
                                    </div>

                                    <div className="bg-single-services">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100.37" height="103.729" viewBox="0 0 192.37 103.729">
                                            <path id="Path_3" data-name="Path 3" d="M1727.629,3351.714l3.848,7.71,188.522-94.1v-9.632Z" transform="translate(-1727.629 -3255.695)" fill="#80808061" />
                                        </svg>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </>
                    :
                    null
            }
        </Layout >
    )
}