import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GlobalState from "./GlobalState";
import ScrollToTop from "./components/ScrollToTop";
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import SingleServices from './pages/SingleServices';
import Insights from './pages/Insights';
import SingleInsights from './pages/SingleInsights';
import Contact from './pages/Contact';
import JoinTheTeam from './pages/JoinTheTeam';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Api from './components/Api';

if (!(localStorage.getItem("language"))) localStorage.setItem("language", "en");

document.getElementsByTagName("html")[0].setAttribute("lang", localStorage.getItem("language") === "en" ? "en" : "ar");
document.getElementsByTagName("html")[0].setAttribute("dir", localStorage.getItem("language") === "en" ? "ltr" : "rtl");
document.getElementById("bootstrap-link").setAttribute("href", localStorage.getItem("language") === "en" ? "/assets/css/bootstrap.min.css" : "/assets/css/bootstrap.rtl.min.css");

axios.defaults.baseURL = 'https://strategyhub.qa/api';
// axios.defaults.baseURL = 'https://test.hellotree.dev/strategy-hub-next-backend/public/api';
axios.defaults.params = {};
axios.defaults.params['locale'] = localStorage.getItem("language");

export default function App() {

  const [fixedTitles, setFixedTitles] = useState();
  const [apiOptions, setApiOptions] = useState({});
  const [globalApiOptions, setGlobalApiOptions] = useState({});
  const [global, setGlobal] = useState();
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    setApiOptions({
      url: '/fixed-titles',
      method: 'post',
      data: {
        "pluck": [
          "title",
          "slug"
        ]
      },
      then: (r) => {
        setFixedTitles(r.data);
        window.triggerScroll();

        // setLoading(false);
      },
    });
    setGlobalApiOptions({
      url: '/global',
      method: 'get',
      then: (r) => {
        setGlobal(r.data);
        // setLoading(false);
        window.triggerScroll();
      },

    });
  }, []);

  return (
    <GlobalState.Provider value={{
      fixedTitles, global
    }}>
      <BrowserRouter>
        <Api options={apiOptions} />
        <Api options={globalApiOptions} />
        <ScrollToTop />
        <Routes>
          <Route path="/join-the-team" element={<JoinTheTeam />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/insights" element={<Insights />} />
          <Route exact path="/insights/:slug" element={<SingleInsights />} />

          <Route path="/services" element={<Services />} />
          <Route exact path="/services/:slug" element={<SingleServices />} />

          <Route path="/about" element={<About />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </GlobalState.Provider>
  );
}

