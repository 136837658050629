export default function ServiceCards(props) {
    return (
        <div className="service-card shadow p-3 h-100">
            <div className="ratio service-card-image">
                <img src={props.image} alt="service" />
            </div>
            <div className="text-center pb-5 pt-4">
                <h3 className="mx-3">{props.title}</h3>
            </div>
        </div>
    )
}